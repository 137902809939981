import Vue from 'vue';
import validateInput from '@/common/validation';

Vue.prototype.ruleEmpty = function (v) {
    if(v == null || v == undefined) {
        v = ''
    }
    const val = v.toString().trim();
    return val !== '' || 'Boş ola bilməz';
};

Vue.prototype.ruleMin = function (v, length) {
    if(length == null) {
        return true
    }
    if(v === null || v === undefined) {
        v = ''
    }
    return (v.length  >= length) || `Minimum ${length} karakter olmalidir`;
};

Vue.prototype.ruleMax = function (v, length) {
    if(length == null) {
        return true
    }
    if(v === null || v === undefined) {
        v = ''
    }
    return (v.length  <= length) || `Maksimum ${length} karakter olmalidir`
};

Vue.prototype.ruleMinValue = function (v, length) {
    if(length == null) {
        return true
    }
    return (v  >= length) || `Minimum ${length} ola biler`;
};

Vue.prototype.ruleMaxValue = function (v, length) {
    if(length == null) {
        return true
    }
    return (v  <= length) || `Maksimum ${length} ola biler`;
};


Vue.prototype.ruleNumbers = function (v) {
    return validateInput(v, 'number') || "Ancaq reqem ola biler";
};

Vue.prototype.rulePhone = function (v) {
    return validateInput(v, 'phone') || "invalid_tel";
};

Vue.prototype.ruleEmail = function (v) {
    return validateInput(v, 'email') || "invalid_email";
};