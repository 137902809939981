export const auth = {
    namespaced: true,
    state: {
        user: null,
    },
    actions: {
        updateUser({ commit }, payload) {
            commit("setUser", payload);
        },
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload;
        }
    }
};