<template>
  <v-app>
    <v-app-bar fixed>
      <div class="header-left">
        <img src="./assets/main-logo.png" alt="" class="main-logo" @click="reload">
      </div>
      <v-spacer></v-spacer>
      <div class="header-right" v-if="user">
        <div class="text-right">
          <p class="mb-1">Əməliyyat Günü: {{ user.sysDate }}</p>
          <p>Sistem Günü: {{ user.bsnsDate }}</p>
        </div>
        <div>
          <router-link to="/" style="text-decoration: none; margin: 0 10px">
            <v-icon size="30">mdi-home</v-icon>
          </router-link>
          <v-icon size="25" @click="logOut">mdi-logout</v-icon>
        </div>
      </div>
    </v-app-bar>
    <v-main class="mt-15 pb-10">
      <router-view />
    </v-main>
    <v-footer fixed>
      <div v-if="user" class="footer">
        <p class="ma-0">İstifadəçi: {{ user.userName }}</p>
        <p class="ma-0">Build: {{ user.buildVersion }}</p>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'App',
  computed: {
    ...mapState('auth', ['user']),
  },
  created() {
    const user = localStorage.getItem('user');
    this.$store.dispatch('auth/updateUser', user ? JSON.parse(user) : null);
  },
  methods: {
    reload() {
      window.location.reload(true);
    },
    logOut() {
      localStorage.removeItem('user');
      this.$store.dispatch('auth/updateUser', null);
      this.$router.push({ name: 'LoginPage' });
    },
  },
};
</script>


<style lang="scss">
.no-border {
  border: 0 !important;
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #fff;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}
.header-left {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .main-logo {
    height: 50px;
    cursor: pointer;
  }

  h3,
  p {
    margin: 0 !important;
    padding: 0 !important;
    line-height: 1;
  }
}

.header-right {
  display: flex;
  justify-content: center;

  h3,
  p {
    margin: 0 !important;
    padding: 0 !important;
    line-height: 1;
  }
}

fieldset {
  border-color: black !important;
}

.v-input--is-disabled fieldset {
  border-color: rgba(0, 0, 0, 0.26) !important;
}

label.v-label--active {
  color: black !important;
}

.v-input--is-disabled label {
  color: rgba(0, 0, 0, 0.26) !important;
}

.v-input__slot {
  min-height: 30px !important;
  max-height: 30px !important;
  display: flex !important;
  align-items: center !important;
  font-size: 12px !important;
  margin: 0 !important;

  .v-label {
    font-size: 12px !important;
  }

  .input__append-inner,
  .v-text-field--enclosed .v-input__append-outer {
    margin-top: 10px;
  }
}

.v-text-field {
  margin: 0 !important;
  padding: 0 !important;
}

.v-text-field__details {
  margin: 2px !important;
  padding: 0 !important;
}

.v-text-field--filled:not(.v-text-field--single-line) input {
  margin-top: 10px !important;
}

.v-text-field--filled .v-label,
.v-text-field--full-width .v-label {
  top: 10px !important;
  font-size: 12px;
}

.v-text-field__slot .v-label:not(.v-label--active) {
  top: 6px !important;
}

.datebox {
  &.v-text-field--enclosed .v-input__append-inner {
    margin-top: 4px !important;
  }
  .v-text-field__slot input:disabled {
    margin-top: 0px !important;
    padding-bottom: 8px !important;
  }
}

.filebox {
  label {
    margin-top: -3px !important;
  }
  .v-input__append-inner {
    margin-top: 3px !important;
  }
}

.v-list-item--dense,
.v-list--dense .v-list-item {
  min-height: 30px !important;
}

.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled .v-label {
  color: black !important;
  cursor: not-allowed;
}

.theme--light input,
.theme--light .v-label {
  padding-left: 5px !important;
}

.disabled .v-input__slot {
  background: rgba(213, 213, 213, 0.26) !important;
}

.footer {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>