import Vue from 'vue';

Vue.prototype.getRule = function (item) {
    if(item.readonly){
        return [];
    }
    const ruleArray = []
    if (item.required) {
        ruleArray.push(this.ruleEmpty)
    }

    if (item.type === "numberbox") {
        ruleArray.push(this.ruleNumbers)
    }

    ruleArray.push(this.ruleMin(item.value, item.minLength))
    ruleArray.push(this.ruleMax(item.value, item.maxLength))
    ruleArray.push(this.ruleMinValue(item.value, item.minValue))
    ruleArray.push(this.ruleMaxValue(item.value, item.maxValue))
    return ruleArray
};

Vue.prototype.hex2a = function (hexx) {
    var hex = hexx.toString();//force conversion
    var str = '';
    for (var i = 0; i < hex.length; i += 2)
        str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    return str;
}

Vue.prototype.isObjectEmpty = function (obj) {
    return Object.keys(obj).length === 0;
}