import Vue from 'vue'
import Vuex from 'vuex'
import { loader } from './modules/loader';
import { auth } from './modules/auth';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tab: null,
    gridTab: null,
    json: {},
    gridJson: {},
    searchQuery: null,
    tranIdValue : 0
  },
  getters: {
  },
  mutations: {
    setTab(state, payload) {
      state.tab = payload
    },
    setGridTab(state, payload) {
      state.gridTab = payload
    },
    setComponents(state, payload) {
      state.json = payload
    },
    setGridComponents(state, payload) {
      state.gridJson = payload
    },
    setSearchQuery(state, payload) {
      state.searchQuery = payload
    },
          setTranValue(state, payload) {
        state.tranIdValue = payload
      }
  },

  actions: {
    updateTranValue({commit}, payload) {
      commit('setTranValue', payload)
    }
  },
  modules: {
    loader,
    auth,
  },
})
