import Vue from "vue";

export const loader = {
    namespaced: true,
    state: {
        loading: false,
        requestsPending: 0,
    },
    actions: {
        show({ commit }) {
            commit("show");
        },
        hide({ commit }) {
            commit("hide");
        },
        pending({ commit }) {
            commit("pending");
        },
        done({ commit }) {
            commit("done");
        }
    },
    mutations: {
        show(state) {
            Vue.prototype.$swal.fire({
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                background: "transparent",
                iconHtml: '<div class="lds-facebook"><div></div><div></div><div></div></div>',
                customClass: {
                    icon: 'no-border'
                },
                onOpen: () => {
                    this.$swal.showLoading();
                }
            })
            state.loading = true;
        },
        hide(state) {
            Vue.prototype.$swal.close()
            state.loading = false;
        },
        pending(state) {
            if (state.requestsPending === 0) {
                this.commit("loader/show");
            }

            state.requestsPending++;
        },
        done(state) {
            if (state.requestsPending >= 1) {
                state.requestsPending--;
            }

            if (state.requestsPending <= 0) {
                this.commit("loader/hide");
            }
        }
    }
};