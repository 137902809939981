/* eslint-disable */
export default (value, type) => {
    switch (type) {
        case 'email':
            return email(value);
        case 'phone':
            return phone(value);
        case 'number':
            return number(value);
        default:
            return true;
    }
};

function email(value) {
    if (!value) {
        return true;
    }
    value = value.trim().toLowerCase();

    const expr =
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])/;

    const match = value.match(expr);

    return !value || (match && match.length > 0 && match[0] === value);
}

function phone(value) {
    if (!value || value.length < 1) return true;
    const expr = /^([0-9+-\/()\s]{7,})?$/;
    return expr.test(value);
}

function number(number) {
    return !isNaN(String(number).replace(',', '.'));
}