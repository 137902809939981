import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'LoginPage',
    component: () => import('../views/LoginPage.vue'),
  },
  {
    path: '/',
    name: 'MenuPage',
    meta: { auth: true},
    component: () => import('../views/MenuPage.vue'),
  },
  {
    path: '/edit',
    name: 'edit',
    meta: { auth: true},
    component: () => import('../views/EditPage.vue'),
  },
  {
    path: '/grid',
    name: 'grid',
    meta: { auth: true},
    component: () => import('../views/GridPage.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  document.title = 'A-BILLING'
  var authed = false
  const authRequired = to.matched.some((route) => route.meta.auth)
  if (to.path == "/login" && localStorage.getItem('user')) { next("/") }
  else if (JSON.parse(localStorage.getItem('user'))) { authed = true } else { authed = false }

  if (authRequired && !authed) {
    next('/login')
  } else {
    next()
  }
})
export default router
